import type {
  IJournalSettings,
  IOrganizationSettings,
  ISharedSetting,
} from '@/Interfaces'
import type { AppRoute } from '@/Types'
import { request } from './request'

let ROUTE_PERMISSIONS: IOrganizationSettings | null = null

export const loadSettings = async (): Promise<IOrganizationSettings> => {
  if (ROUTE_PERMISSIONS) {
    return ROUTE_PERMISSIONS
  }

  const response = await request('organizations/settings')

  if (response.status === 200) {
    const permission = await response.json()
    ROUTE_PERMISSIONS = permission
    return permission as IOrganizationSettings
  }

  return {} as IOrganizationSettings
}

export const isPermittedRoute = async (route: AppRoute) => {
  await loadSettings()
  return isPermittedRouteSync(route)
}

export const isPermittedRouteSync = (route: AppRoute) => {
  if (!route) {
    return true
  }

  if (route.hideInDashboard) {
    return true
  }

  if (!route.permission) {
    return true
  }

  if (!ROUTE_PERMISSIONS) {
    return false
  }

  const permissions = ROUTE_PERMISSIONS as IOrganizationSettings

  if (route.permission === 'aids') {
    return (
      permissions[route.permission].show ||
      permissions[route.permission].show_loans
    )
  }

  if (route.permission === 'journal') {
    const journalSettings: IJournalSettings = permissions[route.permission]
    // We need at least one module is permitted
    return (
      journalSettings.show_conditions ||
      journalSettings.show ||
      journalSettings.show_action_plan
    )
  }

  return ((ROUTE_PERMISSIONS as any)[route.permission] as ISharedSetting).show
}
