<script lang="ts" setup>
import routes from '@/router/router'
import { useHead } from 'unhead'
import { Fragment, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const route = useRoute()

watch(
  () => route.name,
  () => {
    const appRoute = routes.find((item) => item.name === route.name)

    if (appRoute) {
      useHead({ title: `${t(appRoute.titleKey)} - Borgerportalen` })
    }
  }
)
</script>

<template>
  <Fragment></Fragment>
</template>
