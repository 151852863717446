import type { IAuth } from '@/Interfaces'
import SessionManager from '@/utils/session'
import { useAuthStore } from '@/stores/AuthStore'

export default async (request: Request) => {
  const authStorage = new SessionManager<IAuth>('auth')
  const authStore = useAuthStore()
  const auth = authStorage.get()

  const token = authStore.auth.token || auth?.token || ''
  request.headers.set('Authorization', token)

  return request
}
