import { simpleError } from '@/components/shared/SimpleError.vue'
import SessionManager from './session'
import type { IAuth } from '@/Interfaces'
import { useAuthStore } from '@/stores/AuthStore'
import { REPORTABLE_STATUSES_FOR_SENTRY } from '@/Constants'
import { sentryException, toFetchContext } from './sentry'

const { fetch: originalFetch } = window

const DOMAIN = '/api'

// Since we manipulate the `fetch()` function, we have to have the original fetch
// function to send requests to different APIs instead of KMD-API.
window.originalFetch = originalFetch

const authStorage = new SessionManager<IAuth>('auth')

window.fetch = async (...args) => {
  if (import.meta.env.PROD === false) {
    const error = new Error(
      'window.fetch() is deprecated. Use request() function instead.'
    )
    console.warn(error.stack)
  }

  const authStore = useAuthStore()

  const [resource, config] = args

  const auth = authStorage.get()

  const token = authStore.auth.token || auth?.token || ''

  // Setting the headers
  let headers: any = {
    Authorization: token,
  }

  if (!config?.isMultipartFormData) {
    headers['Content-Type'] = 'application/json'
  }

  headers = {
    ...headers,
    ...config?.headers,
  }

  // Sending the request
  const REQUEST_URL = `${DOMAIN}/${resource}`
  const response = await originalFetch(REQUEST_URL, {
    ...config,
    headers,
  })

  // Some of the requests should be reported to Sentry by the HTTP response type
  if (REPORTABLE_STATUSES_FOR_SENTRY.includes(response.status)) {
    const clone = response.clone()
    sentryException(
      new Error(`API error [${response.status}]: ${REQUEST_URL}`),
      await toFetchContext(clone)
    )
  }

  // Authorization error
  if (response.status === 401 && authStore.isCompleted()) {
    // Clear tokens
    authStore.logout()
    // Redirect to the login
    window.router.push('/login')
    // Don't let the caching works
    return response
  }

  if (response.status === 500 && !config?.isErrorModalDisabled) {
    simpleError({ message: 'global.anErrorOccurred', shouldBeTranslated: true })
    // Don't let the caching works
    return response
  }

  // Repond the original response
  return response
}
