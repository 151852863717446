<script lang="ts" setup>
const handleButtonClick = () => {
  const section = document.getElementById('main-section')
  if (section) {
    // Define a list of focusable elements
    const focusableSelectors =
      'a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
    const focusableElements = section.querySelectorAll(focusableSelectors)

    // Focus the first focusable element
    if (focusableElements.length > 0) {
      const element: any = focusableElements[0]
      element.focus()
    }
  }
}
</script>

<template>
  <button
    id="skip-the-content-button"
    type="button"
    class="fixed -top-230 left-[50%] z-100 bg-neutral400 text-neutral1000 px-[14px] py-[10px] text-xl rounded focus:outline-2 outline-neutral1000 shadow focus:top-5"
    :style="{ transform: 'translateX(-50%)' }"
    @click="handleButtonClick"
  >
    {{ $t('global.skipToContent') }}
  </button>
</template>
