import { StatusCode } from '@/Enums'
import { sentryException, toFetchContext } from '../sentry'
import { useAuthStore } from '@/stores/AuthStore'

export const REPORTABLE_STATUSES_FOR_SENTRY: StatusCode[] = [
  StatusCode.BadRequest,
  StatusCode.PaymentRequired,
  StatusCode.Forbidden,
  StatusCode.NotFound,
  StatusCode.MethodNotAllowed,
  StatusCode.NotAcceptable,
  StatusCode.ProxyAuthenticationRequired,
  StatusCode.RequestTimeout,
  StatusCode.Gone,
  StatusCode.PayloadTooLarge,
  StatusCode.URITooLong,
  StatusCode.UnsupportedMediaType,
  StatusCode.TooManyRequests,
  StatusCode.BadGateway,
  StatusCode.ServiceUnavailable,
  StatusCode.GatewayTimeout,
]

export default async (request: Request, response: Response) => {
  const authStore = useAuthStore()

  // Some of the requests should be reported to Sentry by the HTTP response type
  if (REPORTABLE_STATUSES_FOR_SENTRY.includes(response.status)) {
    const clone = response.clone()
    sentryException(
      new Error(`API error [${response.status}]: ${response.url}`),
      await toFetchContext(clone)
    )
  }

  // Authorization error
  if (response.status === StatusCode.Unauthorized && authStore.isCompleted()) {
    // Clear tokens
    authStore.logout()
    // Redirect to the login
    window.router.push('/login')
    // Don't let the caching works
    return response
  }

  return response
}
