export default async (request: Request) => {
  const url = new URL(request.url)
  url.pathname = '/api' + url.pathname

  return new Request(url, {
    method: request.method,
    headers: request.headers,
    body: request.body, // Only for methods like POST, PUT, PATCH
    mode: request.mode,
    credentials: request.credentials,
    cache: request.cache,
    redirect: request.redirect,
    referrer: request.referrer,
    referrerPolicy: request.referrerPolicy,
    integrity: request.integrity,
  })
}
