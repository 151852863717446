<script setup lang="ts">
import { toRefs, computed, type Ref, ref } from 'vue'
import type { ButtonSizes, ButtonTypes } from '@/Types'
import SpinnerComponent from './SpinnerComponent.vue'

interface Props {
  text?: string
  disabled?: boolean
  type?: ButtonTypes
  size?: ButtonSizes
  icon?: string
  fullWidth?: boolean
  gradient?: boolean
  loading?: boolean
  classes?: string
  ariaLabel?: string
  ariaDescribedby?: string
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  disabled: false,
  type: 'primary',
  size: 'medium',
  icon: '',
  fullWidth: false,
  gradient: false,
  loading: false,
})

const rootRef: Ref<HTMLElement | null | undefined> = ref()

defineExpose({ rootRef })

const { text, type, size, disabled, icon, fullWidth, gradient } = toRefs(props)
const isDisabled = computed(() => (disabled.value ? 'disabled' : ''))
const iconOnly = computed(() => !text.value.length && !!icon.value.length)
const iconSize = computed(() => {
  if (size.value === 'small') return 'font-20'
  if (size.value === 'medium') return 'font-20'
  return 'font-24'
})
</script>

<template>
  <button
    ref="rootRef"
    type="button"
    :class="[
      type,
      size,
      isDisabled,
      { 'rounded-full iconOnly justify-center shadow-smallBottom': iconOnly },
      { 'rounded-8': !iconOnly },
      { 'w-fit': !iconOnly && !fullWidth },
      { 'w-full justify-center': fullWidth },
      { gradient: gradient },
      classes,
    ]"
    class="button cursor-pointer flex items-center select-none"
    :aria-disabled="disabled"
    :aria-describedby="ariaDescribedby"
    :disabled="disabled"
  >
    <span class="sr-only" lang="da">{{ ariaLabel }}</span>
    <fa-icon
      v-if="loading === false && icon.length"
      :class="[iconSize, { 'mr-8': !iconOnly }]"
      :icon="['far', `${icon}`]"
    />
    <p v-if="loading === false" class="h300">
      {{ text ? $t(text) : '' }}
    </p>
    <SpinnerComponent v-if="loading" width="w-24" height="h-24" />
  </button>
</template>
