<script setup lang="ts">
import type { AlertTypes } from '@/Types'
import { computed, toRefs } from 'vue'

interface Props {
  title: string
  type?: AlertTypes
  description?: string
  ariaLive?: string
}

interface ITypeTemplate {
  bgColor: string
  color: string
  icon: string[]
}

const props = withDefaults(defineProps<Props>(), {
  type: 'success',
  descriptionParams: undefined,
})

const { title, description, ariaLive } = toRefs(props)

// Color and icon themes by the alert type
const THEMES: Record<AlertTypes, ITypeTemplate> = {
  success: {
    bgColor: 'bg-green400',
    color: '',
    icon: ['fas', 'check'],
  },
  error: {
    bgColor: 'bg-red400',
    color: '',
    icon: ['fas', 'xmark'],
  },
  warning: {
    bgColor: 'bg-darkOcean100',
    color: 'text-neutral1000',
    icon: ['fas', 'triangle-exclamation'],
  },
}

const ariaLiveContent = computed(() => {
  // We should be able to define a custom ariaLive label
  if (ariaLive.value) {
    return ariaLive.value
  }

  return `${title.value}. ${description.value || ''}`
})
</script>

<template>
  <div class="w-full flex justify-center py-5">
    <div class="w-full desktop:w-480">
      <div class="flex justify-center mb-5">
        <div
          :class="[
            'rounded-full flex justify-center items-center p-5',
            THEMES[type].bgColor,
            ,
          ]"
        >
          <slot name="icon">
            <fa-icon
              :class="['w-14 h-14 text-neutral0', THEMES[type].color]"
              :icon="THEMES[type].icon"
            />
          </slot>
        </div>
      </div>

      <div aria-live="assertive" role="alert" class="sr-only">
        {{ ariaLiveContent }}
      </div>

      <div
        class="text-center mb-2 text-2xl font-bold text-neutral1000 leading-7"
      >
        {{ title }}
      </div>
      <div v-if="description" class="text-center text-base font-normal mb-24">
        {{ description }}
      </div>

      <div class="flex justify-center">
        <div class="w-full flex flex-col gap-3">
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
