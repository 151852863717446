import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBorderAll,
  faUser,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faCalendar,
  faAngleRight,
  faCheck,
  faPrint,
  faPlus,
  faAngleLeft,
  faPaperclip,
  faXmark,
  faHourglass,
  faMagnifyingGlass,
  faChevronLeft,
  faChevronRight,
  faPenToSquare,
  faTablet,
  faMinus,
  faDownload,
  faFileSignature,
  faSquareQuestion,
  faFileCircleCheck,
  faClock,
  faLocationDot,
  faCircleQuestion,
  faCircleMinus,
  faBan,
} from '@fortawesome/pro-regular-svg-icons'

// REGULAR

library.add(
  faBorderAll,
  faUser,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faCalendar,
  faAngleRight,
  faCheck,
  faPrint,
  faPlus,
  faAngleLeft,
  faPaperclip,
  faXmark,
  faHourglass,
  faMagnifyingGlass,
  faChevronLeft,
  faChevronRight,
  faPenToSquare,
  faTablet,
  faMinus,
  faDownload,
  faFileSignature,
  faSquareQuestion,
  faFileCircleCheck,
  faClock,
  faLocationDot,
  faCircleQuestion,
  faCircleMinus,
  faBan
)
