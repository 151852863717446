export enum GlobalEvents {
  FetchStarted = 'fetch-started',
  FetchCompleted = 'fetch-completed',
  SimpleErrorMessage = 'simple-error-message',
  SimpleMessage = 'simple-message',
  Logout = 'logout',
}

export enum FileUploadTypes {
  OnlyPDF = '.pdf',
  OnlyImage = 'image/*',
  OnlyDocumentation = '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ImageAndDocumentations = '.odp,.ods,.odt,.doc,.dot,.png,.pdf,.ppt,.rtf,.xls,.xml,.gif,.bmp,.pps,.txt,.msg,.jpg,.jpeg,.docx,.dotx,.docm,.dotm,.xlsx,.xltx,.xlsm,.xltm,.xlsb,.pptx',
}

export enum StatusCode {
  // Informational responses (100–199)

  /**
   * 100 Continue: The server has received the request headers, and the client should proceed to send the request body.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/100
   */
  Continue = 100,

  /**
   * 101 Switching Protocols: The requester has asked the server to switch protocols, and the server has agreed to do so.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/101
   */
  SwitchingProtocols = 101,

  /**
   * 102 Processing: The server has received and is processing the request, but no response is available yet.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/102
   */
  Processing = 102,

  // Success (200–299)

  /**
   * 200 OK: The request has succeeded.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/200
   */
  OK = 200,

  /**
   * 201 Created: The request has succeeded, and a new resource has been created as a result.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/201
   */
  Created = 201,

  /**
   * 202 Accepted: The request has been received but not yet acted upon.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/202
   */
  Accepted = 202,

  /**
   * 203 Non-Authoritative Information: The request was successful, but the information in the response may be from another source.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/203
   */
  NonAuthoritativeInformation = 203,

  /**
   * 204 No Content: The request was successful, but no content is returned.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/204
   */
  NoContent = 204,

  /**
   * 205 Reset Content: The server successfully processed the request, but is not returning any content.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/205
   */
  ResetContent = 205,

  /**
   * 206 Partial Content: The server is delivering only part of the resource due to a range header sent by the client.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/206
   */
  PartialContent = 206,

  /**
   * 207 Multi-Status: Provides status for multiple independent operations.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/207
   */
  MultiStatus = 207,

  /**
   * 208 Already Reported: Used inside a DAV: propstat response element to avoid repeatedly enumerating the same internal members.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/208
   */
  AlreadyReported = 208,

  /**
   * 226 IM Used: The server has fulfilled a GET request for the resource, and the response is a representation of the result.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/226
   */
  IMUsed = 226,

  // Redirection (300–399)

  /**
   * 300 Multiple Choices: Indicates multiple options for the resource from which the client may choose.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/300
   */
  MultipleChoices = 300,

  /**
   * 301 Moved Permanently: The resource requested has been permanently moved to a new URL.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/301
   */
  MovedPermanently = 301,

  /**
   * 302 Found: The URI of the requested resource has been changed temporarily.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/302
   */
  Found = 302,

  /**
   * 303 See Other: The response to the request can be found under another URI using a GET method.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/303
   */
  SeeOther = 303,

  /**
   * 304 Not Modified: Indicates that the resource has not been modified since the version specified by the request headers.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/304
   */
  NotModified = 304,

  /**
   * 305 Use Proxy: The requested resource is only available through a proxy, the address for which is provided in the response.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/305
   */
  UseProxy = 305,

  /**
   * 307 Temporary Redirect: The requested resource resides temporarily under a different URI.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/307
   */
  TemporaryRedirect = 307,

  /**
   * 308 Permanent Redirect: The requested resource is now permanently located at another URI.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/308
   */
  PermanentRedirect = 308,

  // Client errors (400–499)

  /**
   * 400 Bad Request: The server could not understand the request due to invalid syntax.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/400
   */
  BadRequest = 400,

  /**
   * 401 Unauthorized: Authentication is required and has failed or has not yet been provided.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/401
   */
  Unauthorized = 401,

  /**
   * 402 Payment Required: Reserved for future use.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/402
   */
  PaymentRequired = 402,

  /**
   * 403 Forbidden: The client does not have access rights to the content.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/403
   */
  Forbidden = 403,

  /**
   * 404 Not Found: The server can not find the requested resource.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/404
   */
  NotFound = 404,

  /**
   * 405 Method Not Allowed: The request method is known by the server but has been disabled and cannot be used.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/405
   */
  MethodNotAllowed = 405,

  /**
   * 406 Not Acceptable: The server cannot produce a response matching the list of acceptable values defined in the request's proactive content negotiation headers.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/406
   */
  NotAcceptable = 406,

  /**
   * 407 Proxy Authentication Required: Authentication with the proxy is required.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/407
   */
  ProxyAuthenticationRequired = 407,

  /**
   * 408 Request Timeout: The server would like to shut down this unused connection.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/408
   */
  RequestTimeout = 408,

  /**
   * 409 Conflict: The request conflicts with the current state of the server.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/409
   */
  Conflict = 409,

  /**
   * 410 Gone: The content has been permanently deleted from the server.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/410
   */
  Gone = 410,

  /**
   * 411 Length Required: The server refuses to accept the request without a defined Content-Length.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/411
   */
  LengthRequired = 411,

  /**
   * 412 Precondition Failed: Access to the target resource has been denied.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/412
   */
  PreconditionFailed = 412,

  /**
   * 413 Payload Too Large: The request entity is larger than limits defined by the server.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/413
   */
  PayloadTooLarge = 413,

  /**
   * 414 URI Too Long: The URI requested by the client is longer than the server is willing to interpret.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/414
   */
  URITooLong = 414,

  /**
   * 415 Unsupported Media Type: The media format of the requested data is not supported by the server.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/415
   */
  UnsupportedMediaType = 415,

  /**
   * 416 Range Not Satisfiable: The range specified by the Range header field in the request cannot be fulfilled.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/416
   */
  RangeNotSatisfiable = 416,

  /**
   * 417 Expectation Failed: The server cannot meet the requirements of the Expect request-header field.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/417
   */
  ExpectationFailed = 417,

  /**
   * 418 I'm a teapot: A fun Easter egg from the HTTP spec.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/418
   */
  ImATeapot = 418,

  /**
   * 421 Misdirected Request: The request was directed at a server that is not able to produce a response.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/421
   */
  MisdirectedRequest = 421,

  /**
   * 422 Unprocessable Entity: The request was well-formed but was unable to be followed due to semantic errors.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/422
   */
  UnprocessableEntity = 422,

  /**
   * 423 Locked: The resource that is being accessed is locked.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/423
   */
  Locked = 423,

  /**
   * 424 Failed Dependency: The request failed due to failure of a previous request.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/424
   */
  FailedDependency = 424,

  /**
   * 425 Too Early: Indicates that the server is unwilling to risk processing a request that might be replayed.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/425
   */
  TooEarly = 425,

  /**
   * 426 Upgrade Required: The client should switch to a different protocol.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/426
   */
  UpgradeRequired = 426,

  /**
   * 428 Precondition Required: The origin server requires the request to be conditional.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/428
   */
  PreconditionRequired = 428,

  /**
   * 429 Too Many Requests: The user has sent too many requests in a given amount of time.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/429
   */
  TooManyRequests = 429,

  /**
   * 431 Request Header Fields Too Large: The server is unwilling to process the request because its header fields are too large.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/431
   */
  RequestHeaderFieldsTooLarge = 431,

  /**
   * 451 Unavailable For Legal Reasons: The user-agent requested a resource that cannot legally be provided.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/451
   */
  UnavailableForLegalReasons = 451,

  // Server errors (500–599)

  /**
   * 500 Internal Server Error: The server has encountered a situation it does not know how to handle.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/500
   */
  InternalServerError = 500,

  /**
   * 501 Not Implemented: The request method is not supported by the server and cannot be handled.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/501
   */
  NotImplemented = 501,

  /**
   * 502 Bad Gateway: The server, while acting as a gateway or proxy, received an invalid response from the upstream server.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/502
   */
  BadGateway = 502,

  /**
   * 503 Service Unavailable: The server is not ready to handle the request.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/503
   */
  ServiceUnavailable = 503,

  /**
   * 504 Gateway Timeout: The server is acting as a gateway and cannot get a response in time.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/504
   */
  GatewayTimeout = 504,

  /**
   * 505 HTTP Version Not Supported: The HTTP version used in the request is not supported by the server.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/505
   */
  HTTPVersionNotSupported = 505,

  /**
   * 506 Variant Also Negotiates: The server has an internal configuration error.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/506
   */
  VariantAlsoNegotiates = 506,

  /**
   * 507 Insufficient Storage: The server is unable to store the representation needed to complete the request.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/507
   */
  InsufficientStorage = 507,

  /**
   * 508 Loop Detected: The server detected an infinite loop while processing a request.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/508
   */
  LoopDetected = 508,

  /**
   * 510 Not Extended: Further extensions to the request are required for the server to fulfill it.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/510
   */
  NotExtended = 510,

  /**
   * 511 Network Authentication Required: Indicates that the client needs to authenticate to gain network access.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/511
   */
  NetworkAuthenticationRequired = 511,
}
