import type { AppRoute } from '@/Types'

const routes: AppRoute[] = [
  {
    path: '/',
    name: 'overview',
    titleKey: 'global.myOverview',
    component: () => import('@/views/DashboardView.vue'),
    hideInDashboard: true,
  },
  {
    path: '/profile',
    name: 'profile',
    titleKey: 'global.profile',
    component: () => import('@/views/ProfileMainView.vue'),
    hideInDashboard: true,
  },
  {
    path: '/profile-detail',
    name: 'profileDetail',
    titleKey: 'profile.profileInfo',
    component: () => import('@/views/ProfileView.vue'),
    hideInDashboard: true,
  },
  {
    path: '/help',
    name: 'help',
    titleKey: 'global.help',
    component: () => import('@/views/HelpView.vue'),
    hideInDashboard: true,
  },
  {
    path: '/privacy',
    name: 'privacy',
    titleKey: 'global.privacy',
    component: () => import('@/views/PrivacyView.vue'),
    hideInDashboard: true,
  },
  {
    path: '/ibg-profile',
    name: 'ibgProfile',
    titleKey: 'global.ibgProfile',
    component: () => import('@/views/IBGProfileView.vue'),
    hideInDashboard: true,
  },
  {
    path: '/my-data',
    name: 'myData',
    titleKey: 'global.myData',
    permission: 'journal',
    component: () => import('@/views/MyDataView.vue'),
    meta: {
      icon: 'books',
      color: 'pickle300',
    },
  },
  {
    path: '/bodywornAids',
    name: 'bodywornAids',
    titleKey: 'global.bodywornAids',
    permission: 'aids',
    component: () => import('@/views/BodywornAidsView.vue'),
    meta: {
      icon: 'wheelchair',
      color: 'blueberry300',
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    titleKey: 'global.calendar',
    permission: 'calendar',
    component: () => import('@/views/CalendarView.vue'),
    meta: {
      icon: 'calendar',
      color: 'macaron300',
    },
  },
  {
    path: '/messages',
    name: 'messages',
    titleKey: 'global.messages',
    permission: 'messages',
    component: () => import('@/views/MessagesView.vue'),
    count: 0,
    meta: {
      icon: 'envelope-open-text',
      color: 'pumpkin300',
    },
  },
  {
    path: '/messages/:id',
    name: 'messageDetail',
    titleKey: 'global.messages',
    component: () => import('@/views/MessagesDetailView.vue'),
    hideInDashboard: true,
  },
  {
    path: '/courses',
    name: 'courses',
    permission: 'courses',
    titleKey: 'global.courses',
    component: () => import('@/views/CoursesView.vue'),
    meta: {
      icon: 'medal',
      color: 'dragonfruit300',
    },
  },
  {
    path: '/activities',
    name: 'activities',
    titleKey: 'global.activities',
    permission: 'activities',
    component: () => import('@/views/ActivitiesView.vue'),
    requiresGDPR: true,
    meta: {
      icon: 'person-running',
      color: 'plum300',
    },
  },
  {
    path: '/courses/:id',
    name: 'courseDetail',
    titleKey: 'global.courses',
    component: () => import('@/views/CoursesDetailView.vue'),
    hideInDashboard: true,
  },
  {
    path: '/schemas',
    name: 'schemas',
    permission: 'schemas',
    titleKey: 'global.schemas',
    component: () => import('@/views/SchemasView.vue'),
    meta: {
      icon: 'file-signature',
      color: 'blueberry500',
    },
  },
  {
    path: '/news',
    name: 'news',
    titleKey: 'global.news',
    permission: 'news',
    component: () => import('@/views/NewsView.vue'),
    requiresGDPR: true,
    meta: {
      icon: 'megaphone',
      color: 'mango300',
    },
  },
  {
    path: '/news/:id',
    name: 'newsDetail',
    titleKey: 'global.news',
    component: () => import('@/views/NewsDetailView.vue'),
    hideInDashboard: true,
  },
  {
    path: '/grants',
    name: 'grants',
    titleKey: 'global.grants',
    permission: 'grants',
    component: () => import('@/views/GrantsView.vue'),
    meta: {
      icon: 'list-check',
      color: 'cucumber300',
    },
  },
  // {
  //   path: '/components',
  //   name: 'components',
  //   component: () => import('@/views/ComponentsView.vue'),
  //   meta: {
  //     icon: 'megaphone',
  //     color: 'mango300',
  //   },
  //   hideInDashboard: true,
  // },
  {
    path: '/callback',
    name: 'callback',
    titleKey: 'global.loading',
    component: () => import('@/views/CallbackView.vue'),
    hideInDashboard: true,
  },
  {
    path: '/login',
    name: 'login',
    titleKey: 'onboarding.title',
    component: () => import('@/views/OnboardingView.vue'),
    hideInDashboard: true,
  },
]

export default routes
